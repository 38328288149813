<template>
  <div class="tab_wrap">
    <div class="tab_">
      <button
        :class="tab_index == index ? 'on li' : 'li'"
        v-for="(item, index) in tab_list"
        :key="index"
        @click="change_tab(index)"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab_index: 0,
    };
  },
  name: "tab",
  props: {
    tab_list: Array,
  },
  mounted() {},
  methods: {
    change_tab(index) {
      this.tab_index = index;
      this.$emit("tab_index", this.tab_index);
    },
  },
};
</script>
<style>
</style>

<style lang="less" scoped>
.tab_ {
  button {
    border: 0;

    background-color: transparent;

    outline: none;
  }
}

.tab_wrap {
  .tab_ {
    display: flex;
    align-items: center;
    .li.on {
      font-weight: bold;
    }
    .li.on::after {
      content: "";
      display: block;
      width: 22px;
      height: 4px;
      background: #479dff;
      border-radius: 2px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .li {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      margin-right: 90px;
      cursor: pointer;
    }
    .li:last-of-type {
      margin: 0;
    }
  }
}
</style>