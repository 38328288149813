<template>
  <div class="page_my_order right_connent">
    <div class="title">我的优惠</div>
    <tab_comm :tab_list="tab_list" @tab_index="tab_index" />
    <ul class="connent_wrap">
      <li v-for="(item, index) in list_data" :key="index">
        <div class="left" v-if="item.back_type == 2">
          {{ item.back_money | formatDate }}折
        </div>
        <div class="left" v-else>￥{{ item.back_money | formatDate }}</div>
        <div class="right">
          <div class="name">{{ item.coupon_name }}</div>
          <div class="time">{{ item.expire_time }}</div>
          <div class="dec">
            {{ item.description }}
          </div>
        </div>
        <div class="label" v-if="item.is_use == 1">
          <img src="@/assets/mine/is_use.png" alt="" />
        </div>
        <div class="label" v-if="item.is_expire == 1">
          <img src="@/assets/mine/is_e.png" alt="" />
        </div>
      </li>
    </ul>
    <div class="page_wrap">
      <el-pagination
        background
        :pager-count="5"
        :page-sizes="[3, 5, 10, 20]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @prev-click="prev_click"
        @next-click="next_click"
        @current-change="current_change"
        @size-change="size_change"
          :current-page="page"
        :hide-on-single-page="true"
      >
      </el-pagination>
    </div>
    <div
      class="no_data"
      style="
        text-align: center;
        color: #999999;
        margin-top: 100px;
      "
      v-show="total == 0"
    >
      <img style="width: 145px" src="@/assets/common/nodata.png" alt="" />
      <p style="margin-top: 10px;">暂无优惠券</p>
    </div>
  </div>
</template>

<script>
import tab_comm from "@/components/tab_comm.vue";
import api from "@/config/api.js";
export default {
  name: "my_coupon",
  data() {
    return {
      tab_list: [
        {
          id: 0,
          name: "可使用",
        },
        {
          id: 1,
          name: "已使用",
        },
        {
          id: 2,
          name: "已失效",
        },
      ],
      page: 1,
      limit: 10,
      type: 1,
      total: null,
      list_data: [],
    };
  },
  filters: {
    formatDate(data) {
      const back_money = parseInt(data);
      return back_money;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    tab_index(tab_index) {
      if (tab_index == 0) {
        this.type = 1;
      } else if (tab_index == 1) {
        this.type = 2;
      } else if (tab_index == 2) {
        this.type = 3;
      }
      // console.log(this.type, tab_index);
      this.getData();
    },
    prev_click(e) {
      this.page = e;
      this.getData();
    },
    next_click(e) {
      this.page = e;
      this.getData();
    },
    current_change(e) {
      console.log(e);
      this.page = e;
      setTimeout(() => {
        this.getData();
      }, 500);
    },
    size_change(e) {
      console.log(e);
      this.limit = e;
      this.getData();
    },

    async getData() {
      try {
        const res = await api.my_coupon({
          page: this.page,
          limit: this.limit,
          type: this.type,
        });
        if (res.code == 0) {
          this.list_data = res.result.data;
          this.total = res.result.count;
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    tab_comm,
  },
};
</script>
<style scoped>
</style>

<style lang="less" scoped>
.right_connent {
  position: relative;
  padding: 40px 40px;
  background: #ffffff;
  border-radius: 16px;
  width: 820px;
  min-height: 674px;
  .nav_name {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}
.page_wrap {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
.page_my_order.right_connent {
  background-color: #fff;
  text-align: left;
  .title {
    font-size: 20px;
    
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 35px;
    // border-bottom: 1px solid #e9e9e9;
  }
  .connent_wrap {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #e9e9e9;
    display: flex;
    flex-wrap: wrap;
    li:nth-of-type(2n) {
      margin-right: 0;
    }
    li {
      margin: 0 44px 30px 0;
      display: flex;
      align-items: center;
      width: 388px;
      height: 123px;
      background: url(../../assets/mine/coun_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .label {
        width: 53px;
        height: 53px;
        position: absolute;
        right: 0px;
        top: 0px;
        img {
          width: 53px;
          height: 53px;
        }
      }
      .left {
        font-size: 37px;
        
        font-weight: 600;
        color: #cbcacd;
        line-height: 65px;
        margin-left: 12px;
        width: 103px;
        text-align: center;
      }
      .available {
        color: #f96158 !important;
      }

      .right {
        margin-left: 35px;
        .name {
          font-size: 16px;
          
          font-weight: 600;
          color: #151515;
          margin-bottom: 5px;
          z-index: 111;
        }
        .time {
          font-size: 16px;
          
          font-weight: 400;
          color: #999999;
          line-height: 28px;
          margin-bottom: 5px;
        }
        .dec {
          font-size: 14px;
          
          font-weight: 400;
          color: #666666;
          line-height: 18px;
        }
      }
    }
  }
}
</style>